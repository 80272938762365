import './bootstrap';

class RadioShowHide {
    constructor() {
        this.fields = $('[data-show-by]');
        this.init();
    }

    init() {
        $(document).on('change', 'input[type="radio"]', (event) => {
            this.showHideFields($(event.target));
        });
    }

    showHideFields(radio, name = null) {
        const selectedValue = radio.val();
        const fieldName = radio.data('show-field') ?? name;

        // Loop through each field
        this.fields.each((index, field) => {
            const showBy = $(field).data('show-by');
            const showValues = $(field).data('show-value').split('|');

            // Show or hide the field based on the selected radio button's value
            if (showBy === fieldName && showValues.includes(selectedValue)) {
                $(field).parent().css('display', 'block');
            } else {
                $(field).parent().css('display', 'none');
            }
        });
    }
}

class Project {
    constructor() {
        this.init();
    }

    init() {
        $(document).on('change', 'select[name="project_id"]', (event) => {
            const selectedValue = $(event.target).val();
            const url = $(event.target).data('url');
            const urlParams = new URLSearchParams(window.location.search);
            urlParams.set('project_id', selectedValue);
            window.location.href = url + '?' + urlParams.toString();
        });
    }

    updateUrlParam(params) {
        const urlParams = new URLSearchParams(window.location.search);
        $(params).each(function () {
            const url = $(this).data('url');
            $(this).attr('href', url + '?' + urlParams.toString());
        });
    }

    checkRows() {
        $('[data-mark="true"]').each(function () {
            const $this = $(this);
            const min = $this.data('min');
            const current = $this.data('current');

            let elem = $this.closest("tr");
            if (min > current && current > 0) {
                elem.css('background-color', '#ee8e8e');
                elem.addClass("blinking");
            } else {
                elem.css('background-color', 'initial');
            }
        });
    }
}

$(document).ready(() => {
    const radioShowHide = new RadioShowHide();

    radioShowHide.showHideFields($(".radio-type-selector input[type='hidden']"), "type");

    $(document).ajaxSend(function (event, jqXHR, ajaxOptions) {
        // Re-run the script logic on AJAX call start
        const radioShowHide = new RadioShowHide();
        radioShowHide.showHideFields($(".radio-type-selector input[type='hidden']"), "type");
    });

    $(document).ajaxComplete(function (event, jqXHR, ajaxOptions) {
        const radioShowHide = new RadioShowHide();
        const project = new Project();
        radioShowHide.showHideFields($(".radio-type-selector input[type='hidden']"), "type");
        project.checkRows();
    });

    const project = new Project();
    project.updateUrlParam('.export_this_list');
    $(window).on('popstate', function () {
        project.updateUrlParam('.export_this_list');
    });
});
